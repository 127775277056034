import React, { Component } from 'react'

export class Portfolio extends Component {
  state = {
    videos: [],
    loading: true
  }
  async componentDidMount() {
    // https://www.googleapis.com/youtube/v3/search?
    // key=AIzaSyDlV1_k370BiMqvYQisjHjdCaJ-gOcai40&
    // channelId=UCwGKp5ejXWi6GBItBDrrCbw&
    // part=snippet,id&order=date&maxResults=20
    const response = await fetch(`https://www.googleapis.com/youtube/v3/search?key=AIzaSyDlV1_k370BiMqvYQisjHjdCaJ-gOcai40&channelId=UCwGKp5ejXWi6GBItBDrrCbw&part=snippet,id&order=date&maxResults=8`)
    console.log(response);
    if (response.ok) {
      const json = await response.json()
      this.setState(() => ({
        videos: json.items
      }))
    }
  }


  render() {
    return (
      <div className="main">
          <h1>Portfolio</h1>
          <p>Portfolio is in development.</p>
      </div>
    )
  }
}

// {this.state.videos.map((video) => (
//             <div className="video">
//               <iframe
//                 title={video.id.videoId}
//                 src={`https://www.youtube.com/embed/${video.id.videoId}`}
//                 frameborder="0"
//                 allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
//                 allowfullscreen></iframe>
//             </div>
//           ))}

export default Portfolio
