import React from 'react';
import './App.css';
import Home from "./components/Home"
import Portfolio from "./components/Portfolio"
import Contact from "./components/Contact"
import { BrowserRouter as Router, Route, Switch, Link, NavLink } from "react-router-dom"

const App = () => (
  <Router>
    <div className="app">
      <header className="sc">
        <Link to="/">
          <div className="logo raised">
            goatfish
        </div>
        </Link>
        <nav>
          <ul>
            <li>
              <NavLink to="/portfolio" activeClassName="active">portfolio</NavLink>
            </li>
          </ul>
        </nav>
      </header>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>

          <Route path="/portfolio" exact>
            <Portfolio />
          </Route>

          <Route path="/contact" exact>
            <Contact />
          </Route>
        </Switch>
    </div>
  </Router>
)

export default App;
