import React from 'react'

export default function Portfolio() {
  return (
    <div className="main">
      <h1>Contact</h1>
      <p>Leave a quick message and we'll get back to you.</p>
      <form method="POST" netlify>
        <input type="hidden" name="form-name" value="contact-form" />
        <input required type="text" name="name" placeholder="name" autoFocus />
        <input required type="email" name="email" placeholder="email" />
        <input required type="phone" name="phone" placeholder="phone number" />
        <textarea required type="text" name="message" placeholder="message" />
        <div>
          <button className="raised" type="submit">Submit</button>
        </div>
      </form>
    </div>
  )
}
