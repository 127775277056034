import React from 'react'
import { Link } from "react-router-dom"

export default function Home() {

    return (
        <main className="home">
            <div className="main">
                <div className="headlines">
                    <h1>Where more happens for less.</h1>
                    <h2>We give small companies access to <span className="emph">high quality videos</span></h2>
                </div>
                <div className="cta">
                    <Link to="/contact">
                        <div className="contact-cta sc raised">
                            contact us
                        </div>
                    </Link>
                </div>
            </div>
        </main>
    )
}
            // <a href="mailto:techbarnytbusiness@gmail.com">contact us</a>